<script>
import {mapGetters} from "vuex";
import {safeNumberConversion as __safety} from "@/utils/tools";

export default {
  name: "PlayerBettingHelper",
  props: {
    isTournamentGame:{
      type: Boolean,
      required: true,
    },
    range: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      betAmount: 0,
      sliderStep: 0,
    }
  },
  /**
   * 버튼 자체는 actions에 존재
   * actions를 클릭하면 helper는 닫힘
   */
  computed: {
    ...mapGetters('game', ['myInfo', 'roomInfo', 'gameInfo', 'currentRoomState']),
    isPreFlop() {
      return this.currentRoomState === this.roomState.FreeFlopAction;
    },
    hasRaised() {
      try {
        const {hasRaised: value} = this.roomInfo;
        return value;
      } catch (e) {
        return false;
      }
    },
    isFirst() {
      return this.isPreFlop && !this.hasRaised;
    },
    minRaise() {
      return this.range[0];
    },
    callBet() {
      return this.roomInfo.callBet || 0;
    },
    smallBlind() {
      if (!this.gameInfo) {
        return 0;
      }

      return __safety(this.gameInfo.blindTable[0].sb);
    },
    bigBlind() {
      if (!this.gameInfo) {
        return 0;
      }

      return __safety(this.gameInfo.blindTable[0].bb);
    },
    cash() {
      return this.myInfo.cash || 0;
    },
    pot() {
      return this.roomInfo.potCash || 0;
    },
    raiseMenuRelay() {
      const maximum = Math.min(this.cash, this.range[1]);

      const allIn = {
        id: 'raise-all-in',
        isAble: maximum <= this.cash,
        amount: maximum,
        label: '최대',
      };

      const minRaise = {
        id: 'raise-min',
        isAble: this.minRaise <= this.cash,
        amount: this.minRaise,
        label: '최소',
      };

      const computedRaiseItems = [
        {
          id: 'raise-full',
          ratio: 1,
          label: '풀',
        },
        {
          id: 'raise-half',
          ratio: 0.5,
          label: '하프',
        },
        {
          id: 'raise-quarter',
          ratio: 0.2,
          label: '쿼터',
        }
      ];

      return [allIn, ...this.makeComputedRaiseMenu(computedRaiseItems), minRaise];
    },
    raiseMenuTournament() {
      const callBet = this.roomInfo.callBet || 0;
      // const blind = this.roomInfo.blind || 0;

      const allIn = {
        id: 'all-in',
        isAble: true,
        amount: Math.min(this.cash, this.range[1]),
        label: '최대',
      };

      const minRaise = {
        id: 'min',
        isAble: true,
        amount: Math.min(this.cash, this.range[0]),
        label: '최소',
      };

      //판돈을 올린 행위를한(다른말로 합의된금액이 존재할경우) 금액이 존재하지 않을경우
      if (!callBet) {
        const oneHalf = Math.floor(this.pot / 2 / 10) * 10;
        const twoThirds = Math.floor(this.pot / 3 / 10) * 10;

        return [
          allIn,
          {
            id: 'pot',
            isAble: this.range[0] <= this.pot && this.cash >= this.pot,
            amount: Math.floor(this.pot / 10) * 10,
            label: '팟',
          },
          {
            id: 'two-thirds',
            isAble: this.range[0] <= twoThirds && this.cash >= twoThirds,
            amount: twoThirds,
            label: '2/3',
          },
          {
            id: 'one-half',
            isAble: this.range[0] <= oneHalf && this.cash >= oneHalf,
            amount: oneHalf,
            label: '1/2',
          },
          minRaise,
        ];
      }

      //판돈을 올린 행위를한(다른말로 합의된금액이 존재할경우) 금액이 존재할경우
      const twoTimes = Math.floor(callBet * 2 / 10) * 10
      const twoAndHalfTimes = Math.floor(callBet * 2.5 / 10) * 10
      const threeTimes = Math.floor(callBet * 3 / 10) * 10;
      // console.log(callBet, this.pot, `${Math.round(callBet * 2 / this.pot * 100)}%`)

      return [
        allIn,
        {
          id: 'x3',
          isAble: this.range[0] <= threeTimes && this.cash >= threeTimes,
          amount: threeTimes,
          label: `${Math.round(callBet * 3 / this.pot * 100)}%`
        },
        {
          id: 'x2.5',
          isAble: this.range[0] <= twoAndHalfTimes && this.cash >= twoAndHalfTimes,
          amount: twoAndHalfTimes,
          label: `${Math.round(callBet * 2.5 / this.pot * 100)}%`
        },
        {
          id: 'x2',
          isAble: this.range[0] <= twoTimes && this.cash >= twoTimes,
          amount: twoTimes,
          label: `${Math.round(callBet * 2 / this.pot * 100)}%`
        },
        minRaise,
      ];
    }
  },
  watch: {
    bigBlind: {
      immediate: true,
      handler(value, _value) {
        if (_value === undefined) {
          this.sliderStep = value;
        }
      }
    },
  },
  methods: {
    /**
     * 베팅이 없는 경우 minRaise
     * @param config
     * @returns {*}
     */
    makeComputedRaiseMenu(config) {
      return config.map(item => {
        const base = Math.floor(this.pot * item.ratio);
        const amount = base + (this.isFirst ? this.minRaise : this.callBet);
        item.amount = amount;
        item.isAble = this.minRaise <= amount && this.cash >= amount;
        return item;
      });
    },
    /**
     * slider의 handler
     */
    checkBetAmount(event) {
      const newValue = Number(event.target.value);

      if (!isNaN(newValue)) {
        this.betAmount = this.clamp(newValue);
      }
    },
    emitValue() {
      this.$emit('confirm', this.betAmount);
    },
    /**
     * 계산된 Raise 옵션 선택 handler
     * @param raiseInfo
     */
    raise(raiseInfo) {
      if (!raiseInfo.isAble) {
        return;
      }

      this.betAmount = this.clamp(raiseInfo.amount);
      this.emitValue();
    },
    increase() {
      this.betAmount = this.clamp(this.betAmount + this.sliderStep);
      this.emitValue();
    },
    decrease() {
      this.betAmount = this.clamp(this.betAmount - this.sliderStep);
      this.emitValue();
    },
    clamp(value) {
      const maxDiff = Math.abs(this.range[1] - value);

      if (maxDiff && maxDiff <= this.sliderStep) {
        return value + maxDiff;
      }

      // value: 120, this.minRaise: 100, this.sliderStep: 25 일때 설정되는 값이 140이라서... 주어진 값이 아닌 다른값이 설정되는 버그패치? 왜이게 있는지 모르겟음
      // const minDiff = Math.abs(this.minRaise - value);
      //
      // if (minDiff && minDiff < this.sliderStep) {
      //   return value + minDiff;
      // }

      // console.log(`value: ${value}, this.minRaise: ${this.minRaise}, this.range[1]: ${this.range[1]}`)

      return Math.min(Math.max(value, this.minRaise), this.range[1]);
    },
    autoClose(event) {
      event.preventDefault();
      event.stopImmediatePropagation();

      const {target} = event;
      const {helper} = this.$refs;

      !helper.contains(target) && this.$emit('close', false);
    },
    getRaiseMenu(){
      return this.isTournamentGame ? this.raiseMenuTournament : this.raiseMenuRelay;
    },
  },
  created() {
    this.raise(this.raiseMenuTournament[this.raiseMenuTournament.length - 1]);
  },
  mounted() {
    document.addEventListener('click', this.autoClose, false);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.autoClose, false);
  }
}
</script>

<template>
  <div ref="helper" class="layerpopup_bet">
    <div class="bet_point">{{ betAmount | formatCash }}</div>
    <div class="bet_point_select">
      <div class="btn_bet_fix">
        <button
          v-for="raiseInfo in getRaiseMenu()"
          :key="raiseInfo.id"
          type="button"
          class="btn_d btn_dark_gray raise-shortcut"
          :class="{disabled: !raiseInfo.isAble}"
          @click.prevent.stop="raise(raiseInfo)"
        >
          {{ raiseInfo.label }}
        </button>
      </div>
      <div class="bet_range">
        <input
          type="range"
          id="verticalRange"
          class="vertical-slider"
          orient="vertical"
          :step="sliderStep"
          :min="minRaise"
          :max="range[1]"
          :value="betAmount"
          @input="checkBetAmount"
          @change="emitValue"
        />
      </div>
    </div>
    <div class="area_bet_add">
      <button type="button" class="btn_d btn_sky raise-increase" @click.prevent.stop="increase">
        <img src="@/assets/v2/svg/ico_bet_add.svg"/>
      </button>
      <button type="button" class="btn_d btn_sky decrease" @click.prevent.stop="decrease">
        <img src="@/assets/v2/svg/ico_bet_minus.svg"/>
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.raise-shortcut {
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    touch-action: none;
  }
}

button {
  transition: transform 50ms ease-in-out, background-color 50ms ease;
}

button.btn_dark_gray:active {
  transform-origin: center center;
  transform: scale(0.94);
  font-weight: bold;
  opacity: 0.8;
}

button.btn_sky:active {
  transform-origin: center center;
  transform: scale(0.94) translateY(1px);
  font-weight: bold;
}
</style>